<template>
  <a-modal
    width="620px"
    class="create-organization-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="value"
    @cancel="toggle">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.addAnOrganization') }}
    </h3>
      <a-form :form="organization">
        <a-form-item :label="$t('components.labels.typeOfOrganization')" class="organization-type">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [
                  {
                    required: true,
                    message: $t('components.description.typeOrg'),
                  },
                ],
              },
              ]"
              :get-popup-container="(triggerNode) => triggerNode.parentNode"
              autocomplete="off" @change="onTypeChange">
            <a-select-option value="NGO">
             {{ $t('components.dropdown.ngo') }}
            </a-select-option>
            <a-select-option value="UN agency">
             {{ $t('components.dropdown.unAgency') }}
            </a-select-option>
            <a-select-option value="Government agency">
             {{ $t('components.dropdown.governmentAgency') }}
            </a-select-option>
            <a-select-option value="Research institution">
             {{ $t('components.dropdown.researchInstitution') }}
            </a-select-option>
            <a-select-option value="Academic institution">
             {{ $t('components.dropdown.academicInstitution') }}
            </a-select-option>
            <a-select-option value="Humanitarian Network">
             {{ $t('components.dropdown.humanitarianNetwork') }}
            </a-select-option>
            <a-select-option value="Other">
             {{ $t('components.dropdown.other') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('components.labels.fullNameOfOrganization')">
          <a-input v-decorator="[
            'name',
            {
              rules: [
                {
                  whitespace: false,
                  required: isGovernment ? false : true,
                  message: $t('components.description.nameOrg'),
                  transform: (value) => value && value.trim(),
                },
              ],
            },
          ]" :disabled="isGovernment" autocomplete="off"/>
        </a-form-item>
        <a-form-item :label="$t('components.labels.organizationSetting')" class="organization-setting">
          <a-select v-decorator="[
            'setting',
            {
              rules: [
                {
                  required: true,
                  message: $t('components.description.selectOrgSetting'),
                },
              ],
            },
            ]"
            :get-popup-container="(triggerNode) => triggerNode.parentNode" autocomplete="off">
            <a-select-option value="local">
              {{ $t('components.dropdown.local') }}
            </a-select-option>
            <a-select-option value="global">
              {{ $t('components.dropdown.global') }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$tc('components.labels.country', 1)">
          <a-select
            v-decorator="[
              'country',
              {
                rules: [
                  {
                    required: true,
                    message: $t('components.description.selectCountry'),
                  },
                ]
            }]"
            show-search
            :placeholder="$t('components.description.searchCountry')"
            autocomplete="off"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
            <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option v-for="(country, index) in countryArray"
                :key="index"
                :value="country.name">
                  {{ country.name }}
              </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <a-alert
        v-if="error"
        message="Error"
        :description="$t('values.error')"
        type="error"
        show-icon/>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary" ghost
        size="large"
        @click="toggle">
       {{ $t('components.description.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w170 create-organization-button"
        type="primary"
        size="large"
        :loading="saving"
        :disabled="!isFilled"
        @click="create">
        {{ $t('components.description.create') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { configForPossibleBackendRequest } from '../../../../util/request';
import countryArray from '../../../../util/countries';

export default {
  name: 'CreateOrganization',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    onComplete: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      countryArray,
      isGovernment: false,
      error: undefined,
      saving: false,
      validate: {},
      organization: this.$form.createForm(this, {
        name: 'create_organization',
        onFieldsChange: (_, changedFields) => {
          this.validate = {
            ...this.validate,
            ...changedFields
          };
        }
      })
    };
  },
  computed: {
    isFilled() {
      const values = Object.values(this.validate);
      const keys = values
        .map(property => property.value)
        .filter(value => value);
      if (this.isGovernment) {
        return keys.length === 3;
      }
      return keys.length === 4;
    }
  },
  methods: {
    ...mapActions(['setOrganizations']),
    onTypeChange(value) {
      if (value === 'Government agency') {
        this.isGovernment = true;
        this.organization.resetFields(['name']);
      } else {
        this.isGovernment = false;
      }
    },
    create() {
      this.organization.validateFields(async (err, values) => {
        if (!err) {
          if (this.isGovernment) {
            this.onComplete(null, values);
            this.toggle();
            return;
          }

          this.saving = true;
          const baseConfig = { method: 'post', url: '/organizations', data: values };
          const token = this.$store.getters.token ? this.$store.getters.token : null;
          const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
          try {
            const { data } = await Vue.prototype.$http.request(axiosConfig);
            if (data) {
              const created = data.find(organizaton => organizaton.name === values.name);
              this.setOrganizations(data);
              this.onComplete(created.id, values);
              this.toggle();
              this.$notification.success({
                message: this.$t('values.success'),
                description:
                  this.$t('components.notifications.createdOrganization')
              });
              this.organization.resetFields();
            } else {
              this.error = this.$t('components.notifications.errorSavingOrganization');
            }
          } catch (error) {
            this.error = error.response.data.message;
          } finally {
            this.saving = false;
          }
        }
      });
    },
    toggle(e) {
      this.$emit('input', !this.value);
      this.organization.resetFields();
    },
    validateOnlyCharacters(e) {
      e.target.value = e.target.value.replace(/[0-9]/g, '');
    }
  }
};
</script>

<style lang="scss">
</style>
