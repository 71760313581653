var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"create-organization-modal",attrs:{"width":"620px","dialog-style":{ display: 'none' },"footer":null,"closable":true,"visible":_vm.value},on:{"cancel":_vm.toggle}},[_c('h3',{staticClass:"flex align-center txt-32 txt-bold"},[_vm._v(" "+_vm._s(_vm.$t('components.titles.addAnOrganization'))+" ")]),_c('a-form',{attrs:{"form":_vm.organization}},[_c('a-form-item',{staticClass:"organization-type",attrs:{"label":_vm.$t('components.labels.typeOfOrganization')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('components.description.typeOrg'),
                } ],
            } ]),expression:"[\n            'type',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('components.description.typeOrg'),\n                },\n              ],\n            },\n            ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; },"autocomplete":"off"},on:{"change":_vm.onTypeChange}},[_c('a-select-option',{attrs:{"value":"NGO"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.ngo'))+" ")]),_c('a-select-option',{attrs:{"value":"UN agency"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.unAgency'))+" ")]),_c('a-select-option',{attrs:{"value":"Government agency"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.governmentAgency'))+" ")]),_c('a-select-option',{attrs:{"value":"Research institution"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.researchInstitution'))+" ")]),_c('a-select-option',{attrs:{"value":"Academic institution"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.academicInstitution'))+" ")]),_c('a-select-option',{attrs:{"value":"Humanitarian Network"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.humanitarianNetwork'))+" ")]),_c('a-select-option',{attrs:{"value":"Other"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.other'))+" ")])],1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('components.labels.fullNameOfOrganization')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                whitespace: false,
                required: _vm.isGovernment ? false : true,
                message: _vm.$t('components.description.nameOrg'),
                transform: function (value) { return value && value.trim(); },
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                whitespace: false,\n                required: isGovernment ? false : true,\n                message: $t('components.description.nameOrg'),\n                transform: (value) => value && value.trim(),\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isGovernment,"autocomplete":"off"}})],1),_c('a-form-item',{staticClass:"organization-setting",attrs:{"label":_vm.$t('components.labels.organizationSetting')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'setting',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('components.description.selectOrgSetting'),
              } ],
          } ]),expression:"[\n          'setting',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('components.description.selectOrgSetting'),\n              },\n            ],\n          },\n          ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; },"autocomplete":"off"}},[_c('a-select-option',{attrs:{"value":"local"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.local'))+" ")]),_c('a-select-option',{attrs:{"value":"global"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.global'))+" ")])],1)],1),_c('a-form-item',{attrs:{"label":_vm.$tc('components.labels.country', 1)}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'country',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('components.description.selectCountry'),
                } ]
          }]),expression:"[\n            'country',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('components.description.selectCountry'),\n                },\n              ]\n          }]"}],attrs:{"show-search":"","placeholder":_vm.$t('components.description.searchCountry'),"autocomplete":"off","get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.countryArray),function(country,index){return _c('a-select-option',{key:index,attrs:{"value":country.name}},[_vm._v(" "+_vm._s(country.name)+" ")])})],2)],1)],1),(_vm.error)?_c('a-alert',{attrs:{"message":"Error","description":_vm.$t('values.error'),"type":"error","show-icon":""}}):_vm._e(),_c('div',{staticClass:"flex justify-space-between w100"},[_c('a-button',{staticClass:"mt-24 w170",attrs:{"type":"primary","ghost":"","size":"large"},on:{"click":_vm.toggle}},[_vm._v(" "+_vm._s(_vm.$t('components.description.cancel'))+" ")]),_c('a-button',{staticClass:"mt-24 w170 create-organization-button",attrs:{"type":"primary","size":"large","loading":_vm.saving,"disabled":!_vm.isFilled},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('components.description.create'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }